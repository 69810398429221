import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { Divider, makeStyles } from '@material-ui/core';

import styles from './login.module.css';
import { useAuth } from '../../Context/Auth';

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: '1rem',
  },
}));

const Login = ({ preload }) => {
  const auth = useAuth();
  const classes = useStyles();
  const idRef = useRef();

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [saveId, setSaveId] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['unos_rem_id']);

  const onLogin = (e) => {
    e.preventDefault();

    if (id === '') {
      alert('아이디를 입력하세요');
      return;
    }

    if (password === '') {
      alert('비밀번호를 입력하세요');
      return;
    }

    if (saveId) {
      setCookie('unos_rem_id', id, {
        path: '/',
        maxAge: 2147483647,
        sameSite: 'LAX',
      });
    } else {
      removeCookie('unos_rem_id', { path: '/' });
    }

    auth.onLogin({ id, password });
    /* jwt로 바꾸기~~ */
    /* auth.login_test({ id, password }); */
  };

  const onEnterPress = (e) => {
    const key = e.key;
    if (key === 'Enter') {
      ///console.log('on enter click!!!');
      onLogin(e);
    }
  };

  useEffect(() => {
    if (cookies.unos_rem_id !== undefined) {
      setId(() => cookies.unos_rem_id);
      setSaveId(true);
    }
  }, []);

  useEffect(() => {
    preload();
  }, []);

  const handleOnChange = (e) => {
    let checked = e.currentTarget.checked;
    setSaveId(checked);
  };

  /* POPUP */
  const [popupCookie, setPopupCookie, removePopupCookie] = useCookies([
    'kakao_popup',
    true,
  ]);
  const [popup, setPopup] = useState(popupCookie.kakao_popup || true);
  const onPopupBtn = () => {
    setPopup(false);
  };

  const onPopupNeverAgain = () => {
    setPopup(false);
    setPopupCookie('kakao_popup', false, {
      path: '/',
      maxAge: 7 * 24 * 60 * 60,
      sameSite: 'LAX',
    });
  };

  /* useEffect(() => {
    popupCookie.cs_popup &&
      setPopupCookie('cs_popup', false, {
        path: '/',
        maxAge: 10,
        sameSite: 'LAX',
      });
  }, []); */

  /* POPUP2 */
  const [popup2, setPopup2] = useState(popupCookie.notice_popup || true);
  const onPopup2Btn = () => setPopup2(false);

  const closePopup = () => {
    setPopup2(false);
  };

  const noShowTodayPopup = () => {
    setPopup2(false);
    setPopupCookie('notice_popup', false, {
      path: '/',
      maxAge: 1 * 24 * 60 * 60,
      sameSite: 'LAX',
    });
  };

  return (
    <>
      <div
        className={styles.wrapper}
        style={{
          background: `url('${process.env.PUBLIC_URL}/images/login_bg.jpg') no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <div className={styles.login}>
          <div className={styles.description}>
            <h2 className={styles.text}>
              우노스 페이 전용 어플리케이션을 통해
              <br />
              실시간으로 언제, 어디서든
              <br />
              <strong>간편하게</strong> 매출조회가 가능합니다.
            </h2>
            <h5>{''}</h5>
          </div>

          <form /* onSubmit={onLogin}  */ className={styles.form}>
            <div className={styles.formHeader}>
              <img
                className={styles.logo}
                src={process.env.PUBLIC_URL + '/images/logo512.png'}
                alt='logo'
              />
              <span className={styles.title}>
                UNOSPAY <span className={styles.subTitle}>매장관리</span>
              </span>
            </div>

            <input
              ref={idRef}
              value={id}
              onChange={({ target: { value } }) => setId(value)}
              type='text'
              name='userId'
              placeholder='ID'
              className={styles.input}
              onKeyPress={onEnterPress}
            ></input>
            <input
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
              type='password'
              name='userPwd'
              placeholder='PASSWORD'
              className={styles.input}
              autoComplete='off'
              onKeyPress={onEnterPress}
            ></input>
            <div className={styles.remember}>
              <input
                name='rememberBox'
                type='checkbox'
                onChange={handleOnChange}
                checked={saveId}
              />
              <label>아이디 기억하기</label>
            </div>
            <div className={styles.ask}>
              <span>- 매장관리 관련 문의 FAQ</span>
              <span>- 전화문의 1800-4520</span>
            </div>
            <button
              type='submit'
              name='loginBtn'
              className={styles.button}
              onClick={onLogin}
            >
              Login
            </button>
            <Divider className={classes.divider} />
            <a
              href='http://unospay.com/db_login.php'
              name='prevVerBtn'
              className={styles.prevVerBtn}
              target='_blank'
            >
              이전버전 매장관리 이용하기
            </a>
            {/* <button onClick={() => downloadContent('test.txt', 'hello world')}>
              test
            </button> */}
          </form>
        </div>
      </div>
      {/* popup1 */}
      {/* <div
        className={styles.popup}
        style={{ display: popup === 'false' || !popup ? 'none' : 'flex' }}
      >
        <div className={styles.contents}>
          <a href='https://pf.kakao.com/_Cnwzb'>
            <img
              className={styles.popupImg}
              style={{ width: '100%' }}
              src={
                process.env.PUBLIC_URL + '/images/banner/kakaotalk_banner.png'
              }
              alt='카카오톡 친구추가'
            />
          </a>
        </div>
        <div className={styles.buttons}>
          <button onClick={onPopupNeverAgain}>일주일간 보지 않기</button>
          <button onClick={onPopupBtn}>확인</button>
        </div>
      </div> */}
    </>
  );
};

export default Login;
